
.m_ocb_img {
    margin: 30px;
    width: 140px;
    height: auto;
    /* filter: drop-shadow(0 0 20px blue); */
}

.m_ocb_slider_main {
    width: 100%;
    height: 150px;
    margin: auto;
}

.m_ocb_title {
    color: #FF6F20;
}

.m_ocb_data_con {
    display: flex;
    flex-direction: row;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    height: 100%;
    width: calc(100vw - 54px);
}

.data_slider_wrapper {
    scroll-behavior: smooth;
    max-height: 140px;
    display: flex;
    overflow-x: auto;
}

.data_slider_wrapper::-webkit-scrollbar {
    width: 0;
}

.data_slider_wrapper .item {
    width: 150px;
    height: 120px;
    line-height: 110px;
    text-align: center;
    background-color: white;
    padding: 0 20px;

}

.data_slider_wrapper_prev {
    padding: 5px 7px 5px 7px;
    position: relative;
    left: 0px;
    top: 50%;
    width: 35px;
    

    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.data_slider_wrapper_next {
    padding: 5px 7px 5px 7px;
    position: relative;
    right: 0px;
    top: 50%;
    
    width: 35px;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

@media screen and (max-width: 500px) {
    .data_slider_wrapper_prev {
        display: none;
    }

    .data_slider_wrapper_next {
        display: none;
    }
}