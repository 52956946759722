.m_fginf_main {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 40px;
}


.m_fginf_icon {
    width: 200px;
    height: 70px;
    margin: auto 0 auto auto ;
    object-fit: contain;
    /* max-height: 60px; */
}

.m_fginf_name_con {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.m_fginf_name {
    text-transform: uppercase;
    font-weight: bold; 
    text-align: initial;
    font-size: 22px;
    margin: auto auto 0 0;
    line-break: anywhere;
}

.m_fginf_textcomp {
    text-align: justify;
    margin: 24px auto auto auto;
    font-size: 19px;
    line-height: 22px;
    margin-left: 0;

    
    -webkit-line-clamp: 5; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; /* Обрезаем всё за пределами блока */
}

.m_fginf_textcomp_full {
    -webkit-line-clamp: 100; /* Число отображаемых строк */
    transition: ease-in-out 0.5s;
}

.m_fginf_btn_show_more {
    text-decoration: underline!important;
    border: none!important;
    box-shadow: none!important;
    color: #FF6F20;
}

.m_fginf_btn_show_more:focus {
    color: #FF6F20!important;
}

@media screen and (max-width: 600px) {
    .m_fginf_name_con {
        flex-direction: column-reverse;
    }

    .m_fginf_icon {
        margin: auto;
    }

    .m_fginf_name {
        margin: auto;
        font-size: 19px;
    }
}

@media screen and (max-width: 400px) {
    .m_fginf_textcomp {
        font-size: 17px;
        line-height: 20px;
    }
}


    
/* @media screen and (max-width: 790px) {
    .m_fginf_main {
        width: 100%;
    }
} */