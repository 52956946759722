.ra_m_h_main_con {
    display: flex;
    flex-direction: column;
    text-align: justify;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ra_m_h_title {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 40px;
}

.ra_m_h_sub_title {
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
}