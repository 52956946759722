.ra_m_b_main_con {
    display: flex;
    flex-direction: row;
}
.ra_m_b_main_con_mobile {
    display: none;
    flex-direction: row;
    margin: auto;
}

.ra_m_b_carousel {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.ra_m_b_title {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-top: 30px;
}

@media screen and (max-width: 816px) {
    .ra_m_b_main_con {
        display: none;
    }

    .ra_m_b_main_con_mobile {
        display: flex;
    }
}