.ra_a_hian_main_con {
    display: flex;
    flex-direction: row;
}

.ra_a_hian_icon {
    width: 216px;
    margin: 0 40px  20px 0;
    max-height: 176px;
}

.ra_a_hian_notice {
    font-weight: normal;
    font-size: 16px;
    line-height:  18.75px;
    margin: auto;
}



@media screen and (max-width: 590px) {
    .ra_a_hian_main_con {
        flex-direction: column;
    }

    .ra_a_hian_icon {
        margin: 20px auto;
    }
}