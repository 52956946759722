
.m_featuresgi_main_con {
    display: flex!important;
    flex-direction: column!important;
    max-width: 269px!important;
    padding: 30px;
    /*margin: auto!important;*/
    margin-bottom: auto;
}

.m_fgi_img {
    width: 75px!important;
    height: 75px!important;
    margin: auto!important;
    text-align: center!important;
}

.m_fgi_title_span {
    margin: auto!important;
    padding-top: 30px;
    line-height: 20px!important;
    font-size: 17px;
    font-weight: bold;
    text-align: center!important;
    color: #000000;
}

.m_fgi_text_span {
    margin: auto!important;
    padding-top: 16px;
    line-height: 16px!important;
    font-size: 14px;
    text-align: center!important;
    color: #936B58;
}