.ra_idx_footer_notice {
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

.ra_idx_btn_order {
    background: #E07D34!important;
    color: white!important;
    max-height: 60px;
    margin: auto 0 auto auto;
    display: block;
    width: 200px;
    min-width: 145px;
}

.ra_idx_order_con {
    display: flex;
    flex-direction: row;
}

.ra_idx_sub_title1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: start;
}

.ra_idx_text1 {
    font-size: 16px;
    line-height: 19px;
}

.ra_idx_text2 {
    margin: auto auto auto 0;
    padding-right: 10px;
}

.ra_idx_main_sub_con {
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: justify;
}

.ra_idx_title {
    margin-left: 0px;
}

.ra_idx_tpw_main_con {
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    margin: 20px auto 24px 0;
}

@media screen and (max-width: 680px) {
    .ra_idx_footer_notice {
        font-size: 25px;
    }
}


@media screen and (max-width: 600px) {
    .ra_idx_title {
        font-size: 50px;
    }

    .ra_idx_sub_title1 {
        font-size: 25px;
    }

    .ra_idx_footer_notice {
        font-size: 20px;
    }
}

@media screen and (max-width: 515px) {
    .ra_idx_text2 {
        margin: auto;
        padding-bottom: 20px;
    }

    .ra_idx_btn_order {
        margin: auto;
    }

    .ra_idx_order_con {
        flex-direction: column;
    }
}

@media screen and (max-width: 420px) {
    .ra_idx_title {
        font-size: 40px;
        line-height: 45px;
    }

    .ra_idx_sub_title1 {
        font-size: 20px;
    }

    .ra_idx_footer_notice {
        font-size: 18px;
    }
}
