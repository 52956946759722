.ra_a_bi_main_con {
    display: flex;
    flex-direction: column;
    width: 260px;
    margin: 30px;
}

.ra_a_bi_icon {
    width: 76px;
    margin: 0 auto  20px auto;

}

.ra_a_bi_notice {
    font-weight: normal;
    font-size: 16px;
    line-height:  18.75px;
    text-align: center;
}