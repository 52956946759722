.feedback_carousel  .carousel-control-prev span {
    background-color: red;
}

.feedback_carousel {
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.feedback_carousel .carousel-control-next {
    position: absolute;
    top: 50%;
    bottom: 50%!important;
    right: -100px;
    margin: auto;
    display: flex;
}

.feedback_carousel .carousel-control-prev {
    display: flex;
    position: absolute;
    top: 50%;
    bottom: 50%!important;
    left: -100px;
    margin: auto;
}

.slide_btn_icon {
    color: white;
    font-weight: 900;
    font-size: 30px;
    display: flex;
    padding: 5px;
    border-radius: 50%!important;
    opacity: 1!important;
    visibility: visible;
    background: #FF6F20!important;
}

.m_feedg_clients_trust_us_con {
    margin: 40px auto 60px auto;
    font-size: 40px;
    line-height: 47px;
}

.m_feedg_main_con {
    align-items: center!important;
    padding: 30px 20px 30px 20px!important;
    display: flex!important;
    flex-direction: row!important;
    justify-content: center!important;
    margin: auto!important;
}

.m_feedg_main_sub_con {
    margin: 30px auto 30px auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}

.m_feedg_cards_slider {
    position: relative;
    margin: 0;
    width: 812px;
    height: 100%;
}

.m_feedg_slider_main_con {
    width: 100%;
    max-width: 890px!important;
    height: auto;
    min-width: 300px;
    overflow: hidden;
    margin: auto;
    padding: 20px 5px 20px 5px;
}

.m_feedg_slider_sec {
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* position: relative; */
    margin: auto;
}



@media screen and (max-width: 975px) {
    .m_feedg_slider_main_con {
        width: 740px;
        /* height: 550px; */
    }

    .m_feedg_cards_slider {
        width: 100%;
    }
}

@media screen and (max-width: 960px) {
    .feedback_carousel .carousel-control-next { 
        display: none;
    }

    .feedback_carousel .carousel-control-prev { 
        display: none;
    }
}

@media screen and (max-width: 790px) {
    .m_feedg_slider_main_con {
        width: 100%;
        /* height: 450px; */
    }
}

@media screen and (max-width: 630px) {
    .m_feedg_clients_trust_us_con {
        font-size: 36px;
    }
}


@media screen and (max-width: 560px) {
    .m_feedg_clients_trust_us_con {
        font-size: 34px;
    }
}

@media screen and (max-width: 500px) {
    .slide_btn_icon {
        display: none;
    }
}


/*
@media screen and (max-width: 830px) {
    .m_feedg_slider_main_con {
        width: 600px;
    }
}

@media screen and (max-width: 755px) {
    .m_feedg_slider_main_con {
        width: 500px;
    }
}

@media screen and (max-width: 650px) {
    .m_feedg_slider_main_con {
        width: 400px;
    }
}

@media screen and (max-width: 550px) {
    .m_feedg_slider_main_con {
        width: 300px;
        height: 500px;
    }
}*/

@media screen and (max-width: 670px) {
    .m_feedg_slider_main_con {
        height: 650px;
    }
}

@media screen and (max-width: 540px) {
    .m_feedg_slider_main_con {
        height: 700px;
    }
}

@media screen and (max-width: 500px) {
    .m_feedg_slider_main_con {
        height: 750px;
    }
}

@media screen and (max-width: 450px) {
    .m_feedg_slider_main_con {
        height: 800px;
    }
}

@media screen and (max-width: 410px) {
    .m_feedg_slider_main_con {
        height: 850px;
    }
}

@media screen and (max-width: 430px) {
    .m_feedg_slider_main_con {
        width: 100%;
        /* height: 600px; */
    }
}



