.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.page_title {
  /* background-image: -webkit-linear-gradient(left, #f2a65a, #eb6b9d, #000000);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding-bottom: 10px; */

  margin-left: 70px;
  font-weight: bold;
  font-size: 57px;
  line-height: 67px;
  margin-bottom: 36px;
  text-align: start;
}

.gradient1 {
  /* background-image: -webkit-linear-gradient(left, #f2a65a, #eb6b9d, #000000)!important;
  color: transparent!important;
  -webkit-background-clip: text!important;
  background-clip: text!important; */

  background-image: linear-gradient(43deg, #f2a65a 0%, #b65077 46%, #FFCC70 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all .2s;
}

@media screen and (max-width: 500px) {

  .page_title {
      margin: auto auto 36px auto;
      font-weight: bold;
      font-size: 57px;
      line-height: 67px;
      text-align: start;
  }
}