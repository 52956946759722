
.page_main_con {
    max-width: 966px;
    margin: auto;
}

.idx_ac_main_con {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.idx_ac_about_con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 800px;
    margin-left: 70px;
    margin-bottom: 40px;
    padding: 0 60px 0 0px;
}

.idx_ac_page_title {
    margin-left: 70px;
    font-weight: bold;
    font-size: 57px;
    line-height: 67px;
    color: #000000;
    margin-bottom: 36px;
    text-align: start;
}

@media screen and (max-width: 500px) {
    .idx_ac_page_title {
        margin-left: 0px;
        font-size: 55px;
        line-height: 65px;
    }

    .idx_ac_about_con {
        margin-left: 0px;
        padding-right: 0px;
    }
}

.page_title_partners {
    margin-left: 70px;
    font-weight: bold;
    font-size: 57px;
    line-height: 67px;
    color: #000000;
    margin-bottom: 36px;
    text-align: start;
}

@media screen and (max-width: 600px) {
    .page_title_partners {
        font-size: 40px;
        line-height: 60px;
    }
}

@media screen and (max-width: 540px) {
    .page_title_partners {
        margin: auto auto 36px 70px;
        /* margin-left: 0px; */
        /* margin-bottom: 36px; */
        text-align: start;
    }
}

@media screen and (max-width: 500px) {
    .page_title_partners {
        margin: auto auto 36px 0px;
    }
}

@media screen and (max-width: 375px) {
    /* .page_title {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 57px;
        line-height: 67px;
        color: #000000;
        text-align: center;
    } */
}

@media screen and (max-width: 300px) {
    /* .page_title {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 37px;
        line-height: 67px;
        color: #000000;
        text-align: center;
    } */
}

/*

@media screen and (max-width: 600px) {
    .m_pli_main_con {
        margin: 20px auto 20px auto;
        padding: 24px;
        height: 238px;
        width: 400px;
        background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 410px) {
    .m_pli_main_con {
        margin: 20px auto 20px auto;
        padding: 24px;
        height: 238px;
        background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }
}*/