.page_title_products {
    /* background-image: -webkit-linear-gradient(left, #f2a65a, #eb6b9d, #000000);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text; */
    padding-bottom: 10px;

    margin-left: 70px;
    font-weight: bold;
    font-size: 57px;
    line-height: 67px;
    margin-bottom: 36px;
    text-align: start;
}
.page_main_con {
    max-width: 966px;
    margin: auto;
}

/* .page_title {
    margin-left: 70px;
    font-weight: bold;
    font-size: 57px;
    line-height: 67px;
    color: #000000;
    margin-bottom: 36px;
    text-align: start;
} */

@media screen and (max-width: 500px) {

    .page_title_products {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 57px;
        line-height: 67px;
        text-align: start;
    }
}

@media screen and (max-width: 400px) {
    /* .page_title {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 57px;
        line-height: 67px;
        color: #000000;
        text-align: start;
    } */

    /* .page_title_products {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 57px;
        line-height: 67px;
        color: #000000;
        text-align: start;
    } */
}

@media screen and (max-width: 300px) {
    /* .page_title {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 37px;
        line-height: 67px;
        color: #000000;
        text-align: left!important;
    } */

    /* .page_title_products {
        margin: auto auto 36px auto;
        font-weight: bold;
        font-size: 37px;
        line-height: 67px;
        text-align: left!important;
    } */
}

/*

@media screen and (max-width: 600px) {
    .m_pli_main_con {
        margin: 20px auto 20px auto;
        padding: 24px;
        height: 238px;
        width: 400px;
        background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 410px) {
    .m_pli_main_con {
        margin: 20px auto 20px auto;
        padding: 24px;
        height: 238px;
        background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }
}*/