.m_fgi_trapezoid {
    position: relative;
    width: 50%;
    height: 110%;
    border-radius: 12px 0 0 12px;
    background:#bab9b8;
    -webkit-clip-path: polygon(0 0%, 100% 0%, 80% 100%, 0 100%);
          clip-path: polygon(0 0%, 100% 0%, 80% 100%, 0 100%);
}

.m_fgi_main_con { 
    /* height: 400px; */
    width: 800px;
}

.m_fgi_card {
    height: 300px;
    flex: 1;
    width: 812px;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms linear;
    margin: auto;
    border: none;

    border-radius: 12px;
    /* height: 100%; */
    background: #ffffff;
    display: flex;
    flex-direction: row;
} 

.m_fgi_card.m_fgi_active-slide {
    opacity: 1;
    transform: scale(1);
}

@media screen and (max-width: 850px) {
    .m_fgi_main_con {
        width: calc(100vw - 40px);
    } 
}
    
@media screen and (max-width: 975px) {
    .m_fgi_card {
        width: 665px;
    } 
}

@media screen and (max-width: 790px) {
    .m_fgi_trapezoid {
        display: none;
    }
    .m_fgi_card {
        width: calc(100vw - 120px);
    } 
}

@media screen and (max-width: 650px) {
    .m_fgi_card {
        width: calc(100vw - 40px);
    } 
}
/*
@media screen and (max-width: 755px) {
    .m_fgi_card {
        width: 500px;
    } 
}

@media screen and (max-width: 650px) {
    .m_fgi_card {
        width: 400px;
    } 
}

@media screen and (max-width: 550px) {
    .m_fgi_card {
        width: 300px;
    } 
}

@media screen and (max-width: 430px) {
    .m_fgi_card {
        width: 240px;
    } 
}*/