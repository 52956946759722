
.m_i_main_con {
    display: flex;
    flex-direction: column;
}

.m_i_title {
    text-align: start;
    font-weight: bold;
    margin-bottom: 9px;
}

.m_i_ul {
    padding-left: 0;
}


.m_i_main_con {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
}

/* .m_i_main_con:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
} */

@media screen and (max-width: 830px) {
    .m_i_main_con {
        display: flex;
        flex-direction: column;
        margin-right: 0;
    }
    
    /* .m_i_main_con:last-child {
        display: flex;
        flex-direction: column;
        margin-left: 0;
    } */
}