
.m_ng_main_con {
    align-items: center!important;
    padding-top: 30px!important;
    display: flex!important;
    flex-direction: row!important;
    justify-content: center!important;
    margin: auto!important;
}

.m_ng_main_sub_con {
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* width: calc(100% - 30px); */
}

.m_ng_circle_big {
    margin-left: auto!important;
    z-index: -1!important;
    right: 60px!important;
    top: 20px!important;
    position: relative!important;
    width: 130px!important;
    height: 130px!important;
    background: #FFC655!important;
    border-radius: 50%!important;
}

.m_ng_circle_big_tel {
    margin-left: auto!important;
    z-index: -1!important;
    right: -30px!important;
    top: 100px!important;
    position: relative!important;
    width: 130px!important;
    height: 130px!important;
    background: #FFC655!important;
    border-radius: 50%!important;
}

.m_ng_circle_small {
    z-index: -1!important;
    left: 50px!important;
    bottom: -170px!important;
    position: relative!important;
    width: 80px!important;
    height: 80px!important;
    background: #FF7E55!important;
    border-radius: 50%!important;
}

.m_ng_circle_small_tel {
    display: block;
    z-index: -1!important;
    left: -30px!important;
    bottom: 107px!important;
    position: relative!important;
    width: 80px!important;
    height: 80px!important;
    background: #FF7E55!important;
    border-radius: 50%!important;
}

.m_ng_numbers_con {
    margin: auto!important;
    display: flex!important;
    flex-direction: column!important;
    text-align: center!important;
    background: #F4FCFF!important;
    padding: 60px!important;
}

.m_ng_numbers_con_tel {
    width: 300px;
    margin: auto!important;
    display: flex!important;
    flex-direction: column!important;
    text-align: center!important;
    background: #F4FCFF!important;
    padding: 40px!important;
}

.m_ng_numbers_notice {
    color: #E07D34!important;
    line-height: 16px!important;
    text-align: center!important;
    margin-bottom: 12px!important;
}

.m_ng_numbers_notice_tel {
    color: #E07D34!important;
    line-height: 16px!important;
    text-align: center!important;
    font-size: 14px!important;
    margin-bottom: 30px!important;
}

.m_ng_numbers_title {
    color: #000000!important;
    line-height: 47px!important;
    text-align: center!important;
    font-weight: bold!important;
    font-size: 40px!important;
}

.m_ng_numbers_title_tel {
    color: #000000!important;
    line-height: 28px!important;
    text-align: center!important;
    font-weight: bold!important;
    font-size: 24px!important;
    margin-bottom: 17px!important;
}

.m_ng_numbers_item_title_tel {
    color: #000000!important;
    line-height: 28px!important;
    text-align: center!important;
    font-weight: bold!important;
    font-size: 24px!important;
    margin-bottom: 5px!important;
}

.m_ng_numbers_item_text_tel {
    color: #E07D34!important;
    line-height: 16px!important;
    text-align: center!important;
    margin-bottom: 12px!important;
}


.m_ng_numbers_list_con {
    margin: auto!important;
    display: flex!important;
    flex-direction: row!important;
}

.m_ng_numbers_list_con_tel {
    margin: auto!important;
    display: flex!important;
    flex-direction: row!important;
}



/**/

.is2_slide_con {
    width: 400px;
    height: 240px;
} 

.is2_card2 {
    flex: 1 1;
    width: 300px;
    height: 300px;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms linear;
    margin: auto;
    border: none;
}

.m_ng_numbers_desktop {
    display: flex!important;
    width: 100%!important;
    height: auto;
}

.m_ng_numbers_tel {
    display: none!important;
    width: 300px;
    height: auto;
}



@media screen and (max-width: 950px) {
    .m_ng_numbers_desktop {
        display: none!important;
    }
    
    .m_ng_numbers_tel {
        display: flex!important;
    }
}


@media screen and (max-width: 435px) {
    /* .m_ng_circle_small_tel {
        display: none;
    }

    .m_ng_circle_big_tel {
        display: none;
    } */

    .is2_slide_con {
        /* width: 240px; */
    } 
    
    .is2_card2 {
        /* width: 240px; */
    }

    .m_ng_numbers_tel{
        /* width: 240px; */
    }

    .m_ng_numbers_con_tel {
        /* width: 240px; */
        padding: 20px!important;
    }
}

@media screen and (max-width: 650px) {
    .is2_slide_con {
        width: 300px;
        height: 250px;
    } 
}







