.v_m_jc_conditions_title {
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    color: #936B58;
    max-width: 510px;
}

.v_m_jc_conditions_img {
    width: 500px;
    height: 437px;
    margin-right: 60px;
    display: block;
}

.v_m_jc_conditions_list_con {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.v_m_jc_ul {
    max-width: 510px;
}

.v_m_jc_li {
    text-align: start;
    margin: 8px auto 8px 0;
}

.v_m_jc_li::marker {
    color: #FF6F20;
    font-size: 20px;
}

@media screen and (max-width: 980px) {
    .v_m_jc_conditions_img {
        width: 500px;
        height: 437px;
    }
}

@media screen and (max-width: 950px) {
    .v_m_jc_conditions_img {
        width: 400px;
        height: 349px;
    }
}

@media screen and (max-width: 850px) {
    .v_m_jc_conditions_img {
        width: 300px;
        height: 262px;
    }
}

@media screen and (max-width: 800px) {
    .v_m_jc_conditions_img {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .v_m_jc_conditions_title {
        font-size: 32px;
        line-height: 38px;
    }
}