
.a_ali_main_con {
    display: flex!important;
    flex-direction: row!important;
}

.a_ali_img_con {
    display: flex!important;
    flex-direction: row!important;
}

.a_ali_img {
   width: 48px!important;
   height: 47px!important;
   margin-right: 28px!important;
}

.a_ali_auto_list_con {
    display: flex!important;
    flex-direction: column!important;
}

.a_ali_auto_list_title {
    font-size: 18px;
    line-height: 21,09px;
    color: #000000;
    text-align: left;
    font-weight: 400;
    margin-bottom: 18px;
}

.a_ali_ul {
    font-size: 18px!important;
    line-height: 16.41px!important;
    color: #6189AA!important;
    text-align: left!important;
    padding-left: 15px!important;
}

.a_ali_li {
    margin-bottom: 10px!important;
    font-size: 14px!important;
}