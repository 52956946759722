
.m_aic_tab_content {
    min-height: 380px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}

.m_aic_contact2us_con {
    display: flex;
    flex-direction: column;
    padding: 30px;
}


.m_aic_title {
    font-weight: bold;
    font-size: 14px;
    color: #6B90A5;
    background: #EBF3FF;
}

.m_aic_title.active {
    color: "#ffffff";
    background: #FF6F20!important;
}

.m_aic_main_con {
    margin-top: 20px;
}

@media screen and (max-width: 430px) {
    .m_aic_title {
        font-size: 12px;
    }
}

@media screen and (max-width: 1160px) {
    .m_aic_main_con {
        margin-top: 40px;
    }
    
}