.ra_a_ei_main_con {
    /* display: flex; */
    flex-direction: row;
    font-size: 18px;
    line-height: 32px;
    flex-wrap: wrap;
    word-break: break-all;
}

.ra_a_ei_title {
    font-weight: 500;
    margin-right: 10px;
}

