
.is2_card.is2_active-slide {
    opacity: 1;
    transform: scale(1);

}

.is2_card {
    flex: 1;
    max-width: 200px;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms linear;
    margin: auto;
    border: none;
}

@media screen and (max-width: 500px) {
    /* .is2_card {
        flex: 1;
        max-width: 200px;
        opacity: 0;
        transform: scale(1);
        transition: opacity 300ms linear;
        margin: auto;
        border: none;
    } */
}