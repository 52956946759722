
.m_ib_main_con {
    display: flex;
    flex-direction: row;
}

.m_ib_info_con {
    margin: auto;
}

.m_ib_info_sub_con {
    display: flex;
    flex-direction: column;
}

.m_ib_contacts_title {
    text-align: start;
    font-weight: bold;
    margin-bottom: 9px;
    font-size: 20px;
}


.m_ib_contacts_title_top {
    display: none;
    text-align: center;
    font-weight: bold;
    margin-bottom: 9px;
    font-size: 20px;
}

.m_ib_commercial_title {
    text-align: start;
    font-weight: bold;
    margin-bottom: 9px;
    font-size: 16px;
}

.m_ib_icon_location_map_con {
    margin: auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 20px 0px #FF6F20;
}

.m_ib_icon_location_map {
    width: 512px;
    height: 260px;
    margin: auto;
}

@media screen and (max-width: 520px) {
    .m_ib_icon_location_map {
        width: 400px;
        height: 200px;
        margin: auto;
    }
}

@media screen and (max-width: 520px) {
    .m_ib_icon_location_map {
        width: 300px;
        height: 150px;
        margin: auto;
    }
}

@media screen and (max-width: 830px) {
    .m_ib_main_con {
        display: flex;
        flex-direction: column;
    }

    .m_ib_info_con {
        position: relative;
        left: -20px;
        bottom: -20px;
        margin: auto;
        display: flex;
        flex-direction: row;
        background-image: url("../../../common/assets/icons/IconLocationMap2.png");
        background-size: 100% 100%;
        width: 100vw;
        height: calc(100vw/1.22);
    }

    .m_ib_info_sub_con {
        position: relative;
        top: 44px;
        left: 24px;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(100px);
        padding: 17px;
        border-radius: 12px;
        max-height: 280px;
        max-width: 300px;
    }

    .m_ib_contacts_title {
        display: none;
        text-align: center;
    }

    .m_ib_icon_location_map_con {
        display: none;
    }

    .m_ib_contacts_title_top {
        display: block!important;
    }
}

@media screen and (max-width: 500px) {
    .m_ib_info_con {
        margin: auto;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 400px) {
    .m_ib_info_sub_con {
        top: 14px;
    }
    
    .m_ib_info_con {
        background: #ffffff;
        margin-bottom: 30px;
    }

    .m_ib_contacts_title_top {
        display: none!important;
    }
    
    .m_ib_contacts_title {
        display: block;
        text-align: left;
    }
}