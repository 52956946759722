.footer_tag {
    background: #1B1F3C!important;
    color: white!important;
    height: 60px!important;
    display: flex!important;
    flex-direction: column!important;
}

.footer_com_info {
    margin: auto;
    font-size: 14px;
}

.footer_contact_us_con {
    flex-direction: row;
    display: none;
    margin: auto;
    font-size: 14px;
}

.footer_contact_us_con > a {
    color: white!important;
    margin-right: 40px;
}


@media screen and (max-width: 600px) {
    .footer_contact_us_con {
        display: flex;
        gap: 10px;
    }

    .footer_com_info {
        margin: 0 auto auto auto;
        font-size: 12px;
    }
}