.a_ili_li {
    display: flex;
    flex-direction: row;
    padding: 13px 0 13px 0;
}

.a_ili_img {
    width: 30px;
    height: 30px;
    margin: auto 0 auto 0;
}

.a_ili_title {
    margin: auto auto auto 25px;
    max-width: 200px;
    text-align: start;
}

@media screen and (max-width: 830px) {
    .a_ili_li {
        padding: 0px 0 9px 0;
    }
}