
.m_ocfai_main_con {
    align-items: center!important;
    padding-top: 30px!important;
    margin: auto!important;
    padding-bottom: 50px!important;
}

.m_ocfai_call_form_con {
    margin-left: auto!important;
    display: flex!important;
    flex-direction: column!important;
    max-width: 480px!important;
}

.m_ocfai_automate_business_span {
    font-weight: bold;
    font-size: 57px!important;
    line-height: 67px!important;
    text-align: left!important;
    margin-bottom: 15px!important;
}

.m_ocfai_notice_span {
    font-size: 19px!important;
    line-height: 22px!important;
    text-align: left!important;
    color: #936B58!important;
    text-align: center!important;
}

.m_ocfai_phone_con {
    max-width: 400px!important;
    margin-top: 32px!important;
    background: #FFFFFF!important;
    border: 1px solid #936B58!important;
    box-sizing: border-box!important;
    padding: 8px!important;
    display: flex!important;
    flex-direction: row!important;
}

.m_ocfai_phone_form {
    margin: auto!important;
    max-width: calc(100% - 152px)!important;
    background: white!important;
    border: none!important;
    box-shadow: none!important;
}

.m_ocfai_order_call_submit_btn {
    background: #E07D34!important;
    color: white!important;
    margin-left: auto!important;
}

.m_ocfai_icon_con {
    margin-right: auto!important;
    display: block!important;
    width: 580px!important;
    height: 440px!important;
}

.m_ocfai_icon_img {
    width: 100%!important;
    height: 100%!important;
}

@media screen and (max-width: 1160px) {
    .m_ocfai_icon_con {
        /*display: none!important;*/
        width: 380px!important;
        height: 340px!important;
    }
}

@media screen and (max-width: 925px) {
    .m_ocfai_icon_con {
        /*display: none!important;*/
        width: 280px!important;
        height: 240px!important;
    }
}

@media screen and (max-width: 820px) {
    .m_ocfai_automate_business_span {
        text-align: center!important;
        /* font-weight: bold; */
    }


    .m_ocfai_phone_con {
        margin-left: auto!important;
        margin-right: auto!important;
    }
    .m_ocfai_icon_con {
        display: none!important;
    }

    .m_ocfai_call_form_con {
        margin: auto!important;
        display: flex!important;
        flex-direction: column!important;
        max-width: 480px!important;
    }
}


@media screen and (max-width: 600px) {
    .m_ocfai_main_con {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
}

@media screen and (max-width: 540px) {
    
    .m_ocfai_automate_business_span {
        max-width: 100%!important;
        font-size: 44px!important;
        line-height: 45px!important;
    }

    .m_ocfai_notice_span {
        max-width: 100%!important;
    }
}

@media screen and (max-width: 440px) {
    .m_ocfai_automate_business_span {
        width: 100%!important;
        font-size: 37px!important;
        line-height: 37px!important;
    }

    .m_ocfai_notice_span {
        width: 100%!important;
        font-size: 16px!important;
        margin: auto!important;
        text-align: start!important;
    }

    .m_ocfai_phone_con {
        max-width: 400px!important;
        margin-top: 32px!important;
        border: none!important;
        flex-direction: column!important;
        box-sizing: unset!important;
        padding: 0px!important;
    }

    .m_ocfai_phone_form {
        width: 380px!important;
        margin: auto auto 20px auto!important;
        border: 1px solid #936B58!important;
        padding: auto 10px auto 30px!important;
    }

    .m_ocfai_order_call_submit_btn {
        width: 155px!important;
        margin: auto!important;
    }
}

@media screen and (max-width: 410px) {
    .m_ocfai_phone_con {
        width: 100%!important;
    }

    .m_ocfai_phone_form {
        width: 360px!important;
        padding: auto 10px auto 30px!important;
    }
}

@media screen and (max-width: 400px) {

    .m_ocfai_phone_con {
        width: 100%!important;
    }

    .m_ocfai_call_form_con {
        width: 100%!important;
    }
}











