.header_main_con {
    background: white!important;
    min-height: 60px;
    padding: 10px!important;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25)!important;
}

.header_main_con.header_main_con_fixed {
  position: fixed!important;
  top: 0px!important;
  left: 0!important;
  min-width: 100%!important;
  z-index: 999!important;
}

.header_logo_con {
  margin: auto!important;
  padding-right: 30px;
}

.header_main_sub_con {
    margin: auto!important;
    width: 100%!important;
    height: 100%!important;
    display: flex!important;
}

.header_phone_and_mail_con {
    font-weight: 300!important;
    margin: auto 16px auto auto!important;
    line-height: 16,41px!important;
}

.header_btn_contact_us {
    background: #E07D34!important;
    color: white!important;
    max-height: 60px;
    margin: auto;
}

.header_btn_contact_us_con {
    margin: auto 20px auto auto!important;
    display: flex;
    flex-direction: row;
}

/*.header_btn_close_or_open_menu {
    display: none;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 0;
    height: 30px;
    width: 30px;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  
  .header_btn_close_or_open_menu:hover {
    opacity: 0.75;
  }
  
  .header_btn_close_or_open_menu span {
    background-color: rgb(208, 20, 20);
    display: block;
    height: 3px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    position: absolute;
  }
  .header_btn_close_or_open_menu span:after {
    position: absolute;
    background-color: rgb(21, 113, 199);
    content: "";
    display: block;
    height: 3px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    top: 8px;
  }
  
  .header_btn_close_or_open_menu span:before {
    position: absolute;
    background-color: rgb(163, 182, 39);
    content: "";
    display: block;
    height: 3px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    top: -8px;
  }
  
  .header_btn_close_or_open_menu_active > span {
    transform: rotate(45deg);
  }
  .header_btn_close_or_open_menu_active > span::before {
    top: 0;
    transform: rotate(0);
  }
  .header_btn_close_or_open_menu_active > span::after {
    top: 0;
    transform: rotate(90deg);
  }*/

.header_nav_item {
    color: black!important;
    font-weight: 700!important;
    line-height: 16,41px!important;
    margin-left: 20px!important;
    margin-right: 0px!important;
    text-decoration: none!important;
}

.header_nav {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.header_nav_tel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .header_nav_item::before {
  content: '';
  display: inline-flex;
   width: 7px;
   height: 7px;
   -moz-border-radius: 7.5px;
   -webkit-border-radius: 7.5px;
   border-radius: 7.5px;
   background-color: #69b6d5;
   margin-right: 10px;
} */

.header_nav_tel .header_nav_item {
  margin-left: 0!important;
  margin-right: 0!important;
  text-align: start;
}

.header_nav_tel .header_nav_item:not(:first-child) {
  display: block;
  margin-top: 12px;
  /* list-style-position: inside; */
}


.header_nav_con {
    display: block;
}

.header_nav_item.header_nav_item_active {
    text-decoration: none!important;
    border-bottom: 2px solid #E07D34!important;
}

.header_nav_tel .header_nav_item.header_nav_item_active {
  text-decoration: none!important;
  color: #E07D34!important;
  border-bottom: none!important;
}

.header_nav_con_min1100 {
  display: block;
}

.header_nav_con_max1100 {
  display: none;
}


@media screen and (max-width: 960px) {
  /* .header_nav_con_min1100 {
    display: none;
  } */
  
  .header_nav_con_max1100 {
    display: block;
  }
}

/* @media screen and (max-width: 530px) {
  .header_nav {
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;
  }
  
  .header_main_sub_con {
    display: flex!important;
    flex-direction: column!important;
  }
} */


/*-------------btn open menu-------------0*/
.header_btn_menu {
  position: relative;
  width: 30px;
  height: 20px;
  cursor: pointer;
  margin-top: 10px;
  display: none!important;
  box-shadow: none!important;
}

.header_btn_menu i {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 30px;
  height: 4px;
  margin-top: -2px;
  -webkit-transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  -webkit-transition-duration: 75ms;
  transition-duration: 75ms;
  background-color: #26435f;
}

.header_btn_menu i:before,
.header_btn_menu i:after {
  position: relative;
  display: block;
  width: 30px;
  height: 4px;
  content: '';
  -webkit-transition: top 75ms ease .12s, opacity 75ms ease;
  transition: top 75ms ease .12s, opacity 75ms ease;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  background-color: #26435f;
}

.header_btn_menu i:before {
  top: -10px;
}

.header_btn_menu i:after {
  bottom: -6px;
}

.header_btn_menu.header_btn_menu_active i {
  -webkit-transition-delay: .12s;
  transition-delay: .12s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header_btn_menu.header_btn_menu_active i:before {
  top: 0;
  -webkit-transition: top 75ms ease, opacity 75ms ease .12s;
  transition: top 75ms ease, opacity 75ms ease .12s;
  opacity: 0;
}

.header_btn_menu.header_btn_menu_active i:after {
  bottom: 4px;
  -webkit-transition: bottom 75ms ease, -webkit-transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  transition: bottom 75ms ease, -webkit-transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s, -webkit-transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/*-------------btn open menu-------------1*/
/*-------------toggle menu-------------0*/
.header_toggle_menu:not(.header_toggle_menu_fixed) {
  position: absolute!important;
  top: 62px!important;
  left: 0!important;
  bottom: 0!important;
  background: rgba(0, 0, 0, 0.25)!important;
  min-width: 100%!important;
  z-index: 999!important;
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s ease-out;
}

.header_toggle_menu.header_toggle_menu_fixed {
  position: fixed!important;
  top: 62px!important;
  left: 0!important;
  bottom: 0!important;
  background: rgba(0, 0, 0, 0.25)!important;
  min-width: 100%!important;
  z-index: 999!important;
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s ease-out;
}


.header_toggle_menu.header_toggle_menu_active {
  -webkit-transition-delay: .2s;
  transition-delay: .2s;
  -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  visibility: visible;
  opacity: 1;
}

.header_toggle_menu_content {
  position: relative;
  top: 0;
  left: 0;
  padding: 20px;
  background: white;
  display: flex;
  flex-direction: column;
}

.header_main_sub_con > .header_toggle_menu .header_btn_contact_us_con {
  display: flex;
  flex-direction: column-reverse;
  margin: 0!important;
}

.header_main_sub_con > .header_toggle_menu .header_btn_contact_us_con br {
  display: none;
}

.header_main_sub_con > .header_toggle_menu .header_btn_contact_us {
  margin: 0px auto 15px auto!important;
  /*padding: 17px 19px 17px 19px;*/
}

.header_main_sub_con > .header_toggle_menu .header_phone_and_mail_con {
  margin: auto!important;
  /*padding: 17px 19px 17px 19px;*/
}

.header_main_sub_con > .header_toggle_menu .header_phone_and_mail_con a {
  margin-right: 20px;
  /*padding: 17px 19px 17px 19px;*/
}



.header_toggle_menu_hr {
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  display: block;
  border: 1px solid grey;
  opacity: 0.6;
}

/*-------------toggle menu-------------1*/

@media screen and (max-width: 930px) {
  .header_logo_con {
    flex: 1;
  }

  .header_nav {
    display: none;
  }

  .header_btn_menu {
    display: flex!important;
  }

  .header_nav_item {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #363636;
    /*display: list-item;*/
  }
}

@media screen and (max-width: 600px) {
  .header_main_sub_con > .header_btn_contact_us_con {
    display: none;
  }
}


@media screen and (min-width: 930px) {
  .header_toggle_menu {
    display: none!important;
  }
}


/*===========================*/

.user-info {
  border-radius: 12px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px;
  height: 41px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 4px;
  position: relative;
  z-index: 2;
}

.user-info_open {
  overflow: visible;
  background: rgb(191, 109, 109);
  align-self: flex-start;
  margin-top: 4px;
  height: 140px;
  padding-bottom: 0;
  transition: all 0.3s ease-out;
  max-height: 40px;
}

.h_a_mac_sub {
  opacity: 0; 
  max-height: 0px;
  transition: all 0.3s ease-out;
  background: red;
}
/* 
.user-info_open:hover > .h_a_mac_sub {
  transition: all 0.3s ease-in;
  opacity: 1;
} */

.user-info_open:hover > .h_a_mac_sub {/*> .h_a_mac_sub */
  opacity: 1;
  transition: all 0.3s ease-in;
  max-height: 400px;
  /* -webkit-transition: opacity 1s ease-out 0.5s;
     -moz-transition: opacity 1s ease-out 0.5s;
     -o-transition: opacity 1s ease-out 0.5s;
     transition: opacity 1s ease-out 0.5s; */
}

.user-info__data {
  display: flex;
  padding-bottom: 12px;
  align-items: center;
}

.user-info__number {
  margin: 0;
  color: #fff;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  text-decoration-line: underline;
  padding-left: 16px;
}

.user-info__number_open {
  color: #3d4652;
}

.user-info__button {
  background: none;
  outline: none;
  padding: 0;
  margin: 0 0 10px 0;
  border: none;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.user-info__button:nth-of-type(2) {
  margin: 0 0 16px 0;
}
