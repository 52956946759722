
.m_pb_main_con {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}


.m_pb_partner_con {
    display: flex;
    flex-direction: row;
    margin: 20px 0px 20px 0px;
}

.m_pb_partner_img {
    max-width: 218px;
    margin: auto auto auto 0;
}

.m_pb_partner_desc {
    margin: auto auto auto 90px;
    width: calc(100% - 218px);
    padding-left: 20px;
    text-align: justify;
}

.m_pb_hr {
    width: calc(100vw - 45px);
    display: none;
    border: 1px solid grey;
    opacity: 0.6;
}

@media screen and (max-width: 520px) {
    .m_ib_icon_location_map {
        width: 300px;
        height: 150px;
        margin: auto;
    }
}

@media screen and (max-width: 750px) {
    .m_pb_partner_con {
        display: flex;
        flex-direction: column;
    }

    .m_pb_partner_img {
        max-width: 218px;
        margin: auto;
    }


    .m_pb_hr:not(:last-child) {
        display: block;
    }

    .m_pb_partner_desc {
        margin-left: 70px;
        width: calc(100% - 70px);
        padding-top: 20px;
        max-width: 800px;
        padding-left: 0px;
        padding-right: 60px;
    }
}

@media screen and (max-width: 500px) {

    .m_pb_partner_desc {
        margin: auto;
        width: 100%;
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }
}