
.m_cpli_main_con {
    margin: 20px;
    padding: 24px;
    height: 238px;
    width: 282px;   
    background: #FFFCFA;
    box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}


.m_cpli_main_con:hover{
    cursor: pointer;
    box-shadow: 0 0 20px 5px #b39381;
}

.m_spli_modal_body {
    display: flex!important;
    flex-direction: column!important;
    padding: 24px 30px 24px 30px!important;
}

.m_spli_go_back_btn {
    font-size: 12px;
    padding-left: 0px;
    margin-left: 0px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #FF6F20;
}

.m_spli_name {
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    font-weight: bold;
    margin-top: 20px;
}

.m_spli_desc {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 30px;
}

.m_spli_clients_con {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.m_spli_clients_title {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    font-weight: 500;
}


.m_spli_contact_us_btn {
    color: #ffffff;
    background: #FF6F20;
    border-radius: 4px;
    height: 50px;
    margin: auto auto 30px auto;
}

.m_spli_contact_us_btn:hover {
    color: #ffffff!important;
}