.idx_tpw_main_con {
    padding-left: 70px;
    display: flex;
    flex-direction: row;
    margin: 20px auto 24px 0;
}

.idx_tpw_item_first {
    font-size: 16px;
    line-height: 19px;
    color: #FF6F20;
}

@media screen and (max-width: 500px) {
    .idx_tpw_main_con {
        padding-left: 0px;
    }
}
