.v_idx_main_sub_con {
    background: white;
}


.page_main_con {
    max-width: 966px;
    margin: auto;
}

@media screen and (max-width: 900px) {
    .v_idx_main_sub_con {
        width: 100%;
        height: 100%;
        padding: 20px;
        background: url("../../../common/assets/icons/IconVacancySit.png") right bottom no-repeat, url("../../../common/assets/icons/IconVacancyStand.png") left bottom no-repeat;
        background-size: 142px 151px, 71px 185px;
    }
}


/*

@media screen and (max-width: 600px) { 
    .m_pli_main_con {
        margin: 20px auto 20px auto;
        padding: 24px;
        height: 238px;
        width: 400px;
        background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 410px) {
    .m_pli_main_con {
        margin: 20px auto 20px auto;
        padding: 24px;
        height: 238px;
        background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }
}*/