.m_fs_main_con {
    width: 850px;
    height: 500px;
}

.m_fs_section {
    width: 850px;
    height: 400px;
    overflow: hidden;
    position: relative;
    margin: auto;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}


.m_fs_btn-prev {
    position: relative;
    top: calc(50% - 30px);
    left: 32px;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.m_fs_btn-prev:focus {
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
    border-color: rgb(38 143 255 / 50%);
}

.m_fs_btn-next:focus {
    border: rgb(38 143 255 / 50%);
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.m_fs_btn-next {
    position: relative;
    top: calc(50% - 30px);
    right: 32px;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}


/* .m_fs_slides .slick-prev, .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
} */

.m_fs_cards-slider {
    position: relative;
    max-width: 226px;
    margin: 0;
    width: 100%;
    height: 100%;
}

/* .m_fs_card.active-slide {
    opacity: 1;
    transform: scale(1);

} */

.m_fs_cards-slider-wrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    height: 100%;
}

/* .m_fs_card {
    flex: 1;
    width: 100%;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms linear;
    margin: auto;
    border: none;
} */

.m_fs_con-prev {
    height: 100%;
    position: absolute;
    left: 32px;
    font-size: 3rem;
    color: #000000;
    z-index: 3;
    cursor: pointer;
    user-select: none;
}

.m_fs_con-next {
    height: 100%;
    position: absolute;
    right: 32px;
    font-size: 3rem;
    color: #000000;
    z-index: 3;
    cursor: pointer;
    user-select: none;
}

.m_fs_con-prev2 {
    height: 100%;
    position: absolute;
    left: 32px;
    font-size: 3rem;
    color: #000000;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    top: 0;
}

.m_fs_con-next2 {
    top: 0;
    height: 100%;
    position: absolute;
    right: 32px;
    font-size: 3rem;
    color: #000000;
    z-index: 3;
    cursor: pointer;
    user-select: none;
}

.m_fs_btn-prev2 {
    opacity: 0.7;
    position: relative;
    top: calc(50% + 53px);
    left: -60px;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.m_fs_btn-prev2:focus {
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
    border-color: rgb(38 143 255 / 50%);
}

.m_fs_btn-next2:focus {
    border: rgb(38 143 255 / 50%);
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.m_fs_btn-next2 {
    margin-left: auto;
    position: relative;
    top: calc(50% + 20px);
    right: -60px;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}



@media screen and (max-width: 975px) {
    .m_fs_section {
        width: 700px;
        height: 400px;
    }

    .m_fs_main_con {
        width: 700px;
        /* height: 500px; */
    }
}

@media screen and (max-width: 830px) {
    .m_fs_section {
        width: 600px;
    }

    .m_fs_main_con {
        width: 600px;
        /* height: 500px; */
    }
}

@media screen and (max-width: 755px) {
    .m_fs_section {
        width: 500px;
    }

    .m_fs_main_con {
        width: 500px;
        /* height: 500px; */
    }
}

@media screen and (max-width: 650px) {
    .m_fs_section {
        width: 400px;
    }

    .m_fs_main_con {
        width: 400px;
        /* height: 500px; */
    }
}

@media screen and (max-width: 550px) {
    .m_fs_section {
        width: 300px;
        height: 500px;
    }

    .m_fs_main_con {
        width: 300px;
    }
}

@media screen and (max-width: 430px) {
    .m_fs_section {
        width: 240px;
        height: 600px;
    }

    .m_fs_main_con {
        width: 240px;
        height: 600px;
    }
}