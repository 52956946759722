
.p_m_sc_data_slider_wrapper {

    max-height: 120px;
    display: flex;
    overflow-x: auto;
}

.p_m_sc_data_slider_wrapper::-webkit-scrollbar {
    width: 0;
}

.p_m_sc_data_slider_wrapper .p_m_sc_item {
    width: 110px;
    text-align: center;
    background-color: white;
    padding: 0 20px;
    margin: auto;

}

.p_m_sc_data_slider_wrapper_prev {
    padding: 5px 7px 5px 7px;
    position: relative;
    left: 0px;
    top: 50%;
    width: 35px;
    

    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.p_m_sc_data_slider_wrapper_next {
    padding: 5px 7px 5px 7px;
    position: relative;
    right: 0px;
    top: 50%;
    
    width: 35px;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

@media screen and (max-width: 500px) {
    .p_m_sc_data_slider_wrapper_prev {
        display: none;
    }

    .p_m_sc_data_slider_wrapper_next {
        display: none;
    }
}