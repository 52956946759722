@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./SFProDisplay-Regular.ttf") format("ttf"),
    url("./SFProDisplay-Regular.woff") format("woff"),
    url("./SFProDisplay-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./SFProDisplay-Light.ttf") format("ttf"),
    url("./SFProDisplay-Light.woff") format("woff"),
    url("./SFProDisplay-Light.woff2") format("woff2");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./SFProDisplay-Bold.ttf") format("ttf"),
    url("./SFProDisplay-Bold.woff") format("woff"),
    url("./SFProDisplay-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Roboto-Regular.ttf") format("ttf")
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Roboto-Bold.ttf") format("ttf")
}
