.is2_main_con {
    width: 600px;
    height: 300px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.is2_main_sub_con {
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: row;
}

.is2_section {
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* position: relative; */
    margin: auto;
}

.is2_btn_prev {
    z-index: 3;
    cursor: pointer;
    /* user-select: none; */
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
    height: 34px;
    width: 34px;
    margin: auto;
    position: relative;
}

.is2_controls_con {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    position: relative;
    top: 0px;
}


.is2_btn-prev:focus {
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
    border-color: rgb(38 143 255 / 50%);
}

.is2_btn_next:focus {
    border: none;
    box-shadow: none;
}

.is2_btn_prev:focus {
    box-shadow: 0 0 5px 5px rgb(27, 0, 182);
    border-color: rgb(38 143 255 / 50%);
}

.is2_btn_next {
    position: relative;
    height: 34px;
    width: 34px;
    margin: auto;
    z-index: 3;
    cursor: pointer;
    /* user-select: none; */
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}


.is2_slides .is2_slick-prev, .is2_slides .is2_slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;

}
.is2_cards-slider {
    position: relative;
    max-width: 226px;
    margin: 0;
    width: 100%;
    height: 100%;
}

.is2_cards-slider-wrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    height: 100%;
}


@media screen and (max-width: 650px) {
    .is2_btn_prev {
        display: none;
    }

    .is2_btn_next {
        display: none;
    }
}
