
.o_pl_main_con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1000px;
}


@media screen and (max-width: 1075px) {
    .o_pl_main_con {
        max-width: 650px;
        margin: auto;
    }
}

@media screen and (max-width: 675px) {
    .o_pl_main_con {
        max-width: 100%;
    }
}

@media screen and (max-width: 645px) {
    .o_pl_main_con {
        flex-direction: column;
        max-width: 100%;
    }
}


