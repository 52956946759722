.btn-prev {
    position: relative;
    top: calc(50% - 30px);
    left: 32px;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.btn-prev:focus {
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
    border-color: rgb(38 143 255 / 50%);
}

.btn-next:focus {
    border: rgb(38 143 255 / 50%);
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.btn-next {
    position: relative;
    top: calc(50% - 30px);
    right: 32px;
    z-index: 3;
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    padding: 9px;
    display: flex;
    color: white!important;
    background: #FF6F20!important;
    border: 0px;
    box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}


.slides .slick-prev, .slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;

}
.cards-slider {
    position: relative;
    max-width: 226px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.card.active-slide {
    opacity: 1;
    transform: scale(1);

}

.cards-slider-wrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    height: 100%;
}

.card {
    flex: 1;
    max-width: 200px;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms linear;
    margin: auto;
    border: none;
}

.con-prev {
    height: 100%;
    position: absolute;
    left: 32px;
    font-size: 3rem;
    color: #000000;
    z-index: 3;
    cursor: pointer;
    user-select: none;
}

.con-next {
    height: 100%;
    position: absolute;
    right: 32px;
    font-size: 3rem;
    color: #000000;
    z-index: 3;
    cursor: pointer;
    user-select: none;
}


@media screen and (max-width: 500px) {
    .card {
        flex: 1;
        max-width: 200px;
        opacity: 0;
        transform: scale(1);
        transition: opacity 300ms linear;
        margin: auto;
        border: none;
    }

    .con-prev {
        display: none;
    }

    .con-next {
        display: none;
    }

}