
.m_pli2_main_con {
    margin: 20px;
    padding: 24px;
    height: 238px;
    width: 282px;   
    background: #FFFCFA;
    box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.m_pli2_title_icon {
    margin: auto 20px auto 0;
    width: 60px;
    height: 60px;
    max-width: 60px!important;
    min-width: 60px!important;
    max-height: 60px!important;
}

.m_pli2_title_con {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

.m_pli2_main_con:hover{
    cursor: pointer;
    box-shadow: 0 0 20px 5px #b39381;
}


.m_pli2_title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin: auto auto auto 0;
    text-align: start;

    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.m_pli2_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    text-align: start;

    -webkit-line-clamp: 7;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media screen and (max-width: 1075px) {
    .m_pli2_main_con:nth-child(even) {
        margin-right: 0px;
    }

    .m_pli2_main_con:nth-child(odd) {
        margin-left: 0px;
    }
}

@media screen and (max-width: 645px) {
    .m_pli2_main_con {
        width: 100%;
        margin: 10px auto 10px auto!important;
        /* background: #138048; */
    }
}


@media screen and (max-width: 600px) {
    .m_pli2_main_con {
        margin: auto;
        /* padding: 24px;
        height: 238px;
        width: 400px; */
        /* background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25); */
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 410px) {
    .m_pli2_main_con {
        margin: auto;
        /* margin: 20px 20px 20px 20px; */
        /* padding: 24px;
        height: 238px; */
        /*width: 400px;*/
        /* background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25); */
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width: 965px) {
    .m_pli2_main_con {
        margin: 20px;
        padding: 24px;
        height: 238px;
        max-width: 650px;
        /* background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25); */
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width: 650px) {
    .m_pli2_main_con {
        margin: 20px;
        padding: 24px;
        height: 238px;
        max-width: 350px;
        /* background: #ffffff;
        box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.25); */
        display: flex;
        flex-direction: column;
    }
}
