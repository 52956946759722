
.m_ag_main_con {
    align-items: center!important;
    padding: 30px 20px 30px 20px!important;
    display: flex!important;
    flex-direction: row!important;
    justify-content: center!important;
    margin: 30px auto auto auto!important;
}

.m_ag_main_sub_con {
    margin: auto;
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;
}

.m_ag_rectangle1 {
    height: 16px;
    margin: auto;
    position: relative;
    bottom: 0;
    z-index: -1;
    background: #E5F1FF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 350px;
}

.m_ag_rectangle2 {
    height: 16px;
    margin: auto;
    position: relative;
    bottom: 0;
    z-index: -2;
    background: #E5F1FF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 320px;
}

.m_ag_auto_list_con { 
    margin-left: 0px;
    width: 530px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-right: auto;
}

.m_ag_auto_list_title { 
    font-size: 35px;
    line-height: 41px;
    color: #000000;
    text-align: left;
    margin-bottom: 14px;
}

.m_ag_auto_list_sub_title { 
    font-size: 16px;
    line-height: 19px;
    color: #6189AA;
    text-align: left;
    margin-bottom: 22px;
}

.m_ag_slider_card {
    flex: 1 1;
    width: 400px;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms linear;
    margin: auto;
    border: none;
}

.m_ag_slider_main_con {
    width: 400px;
    height: 425px;
    margin: auto;
}

.m_ag_auto_list_con_tel {
    width: 100%;
    display: none;
}

.m_ag_auto_list_con_desktop { 
    width: 100%;
    display: flex;
    flex-direction: column;
}

.m_ag_input_con { 
    width: 380px;
    border-radius: 4px;
    margin: auto;
}


@media screen and (max-width: 1160px) {
    .m_ag_main_sub_con {
        flex-direction: column-reverse;
    }

    .m_ag_auto_list_con { 
        margin: auto;
        width: 530px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 800px) {
    .m_ag_auto_list_con_desktop { 
        display: none;
    }

    .m_ag_auto_list_con_tel {
        display: block;
    }

    .m_ag_auto_list_con { 
        margin: auto;
    }
}

@media screen and (max-width: 540px) {
    .m_ag_auto_list_con { 
        margin: auto;
        width: 100%;
    }

    .m_ag_slider_card {
        width: calc(100vw - 40px);
    }

    .m_ag_slider_main_con {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    /*.m_ag_auto_list_con { 
        width: 300px;
    }

    .m_ag_slider_card {
        width: 240px;
    }

    .m_ag_slider_main_con {
        width: 240px;
    }
*/
    .m_ag_input_con { 
        width: 300px;
    }

    .m_ag_rectangle1 {
        width: 270px;
    }

    .m_ag_rectangle2 {
        width: 240px;
    }
}




