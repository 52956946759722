.ra_m_e_main_con {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.ra_m_e_title {
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
}

.ra_m_e_ul {
    list-style-type: disc;
}

@media screen and (max-width: 480px) {
    .ra_m_e_main_con ul {
        padding-left: 0px;
    }
}
