.v_m_jot_main_con {
    display: flex;
    flex-direction: column;
}

.v_m_jot_sub_con {
    display: flex;
    flex-direction: row;
}

.v_m_jot_contacts_form {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    min-width: 321px;
    margin-left: auto;
}

.v_m_jot_send_con {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.v_m_jot_send_btn {
    background: #FF6F20;
    font-weight: bold;
    color: #ffffff;
}

.v_m_jot_send_btn:hover {
    color: #ffffff;
}

.v_m_jot_title {
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin: 50px auto 50px auto;
}

.v_m_jot_show_attach_con {
    display: flex;
    flex-direction: row;
}

.v_m_jot_del_attach_btn {
    margin: auto 0 auto auto;
}

.v_m_jot_del_attach_btn_icon {
    font-size: 30px;
    padding: 0px;
    transform: rotate(45deg);
}

.v_m_jot_del_attach_btn_text {
    display: none;
}

.v_m_jot_attach_file_btn {
    background: #ffffff;
    font-weight: bold;
}

.v_m_jot_attach_file_btn_text {
    font-size: 14px;
    line-height: 16px;
    color: #545454;
}

.v_m_jot_attach_file_btn_icon {
    width: 25px;
    height: 30px;
    color: #545454;
    transform: rotate(45deg);
}

.v_m_jot_img {
    width: 500px;
    height: 437px;
    margin-left: 100px;
    display: block;
}

@media screen and (max-width: 980px) {
    .v_m_jot_img {
        width: 400px;
        height: 349px;
    }
}


@media screen and (max-width: 900px) {
    .v_m_jot_img {
        width: 300px;
        height: 262px;
    }
}

@media screen and (max-width: 900px) {
    .v_m_jot_img {
        display: none;
    }

    .v_m_jot_contacts_form {
        margin: auto;
    }

    .v_m_jot_main_con {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 600px) {
    .v_m_jot_send_con > button {
        width: 200px;
        margin: auto;
    }
}

@media screen and (max-width: 500px) {
    .v_m_jot_main_con {
        margin-bottom: 60px;
    }
}


