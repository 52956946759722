.h_atom_dm_main_con {
  border-radius: 12px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  height: 41px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 4px;
  position: relative;
  max-width: 100px;

  overflow: visible;
  background: rgb(255, 255, 255);
  align-self: flex-start;
  margin-top: 4px;
  padding-bottom: 0;
  max-height: 26px;
  margin: auto 20px;
  transition: all 0.3s ease-in-out;
}

.h_atom_dm_dropdown_con {
  pointer-events: none;
  opacity: 0;
  display: flex;
  max-height: 0px;
  transition: all 0.5s ease-out;
  background: rgb(255, 255, 255);
  min-width: 200px;
  flex-direction: column;
  border-radius: 12px;
  padding: 14px 0px;
  position: relative;
  top: -35px;
  z-index: -1;

  box-shadow: 
  4px 0px 0px -22px #cacaca, 
  0px 4px 8px -1px #cacaca,
  0px 2px 8px -38px #cacaca, 
  0px 0px 20px -12px #cacaca;
}


.h_atom_dm_main_con:hover {
/* background: red; */
  max-width: 150px;
}

.h_atom_dm_main_con:hover .h_atom_dm_dropdown_item {
  width: 150px;
  line-height: 20px;
  margin-bottom: 8px;
  text-align: center;
  display: block;
  margin: 8px 0px!important;
}

.h_atom_dm_main_con:hover > .h_atom_dm_dropdown_con {
  transition: all 0.5s ease-in;
  max-height: 400px;
  opacity: 1;
  pointer-events: all;
  z-index: 3;
}

.h_atom_dm_main_con .h_atom_dm_title_con {
  padding-top: 3px;
  min-width: 150px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.h_atom_dm_main_con:hover > .h_atom_dm_title_con {
  transition: visibility 1s linear;
  width: 200px;
  opacity: 0;
  /* visibility: collapse; */
  /* height: 0px; */
  /* margin-left: 400px; */
}
/* 
.h_atom_dm_main_con .h_atom_dm_title {
  display: block;
  width: 200px;
  margin: auto;
} */

/*===========================*/
/* .h_atom_dm_main_con {
  border-radius: 12px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 16px;
  height: 41px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 4px;
  position: relative;
  max-width: 150px;

  overflow: visible;
  background: rgb(255, 255, 255);
  align-self: flex-start;
  margin-top: 4px;
  height: 150px;
  padding-bottom: 0;
  transition: height 1s linear;
  max-height: 26px;
 
}

.h_atom_dm_main_con_no_sub {
  border-radius: 12px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 16px;
  height: 41px;
  overflow: hidden;
  cursor: pointer;
  margin-left: 4px;
  position: relative;
  max-width: 150px;

  overflow: visible;
  background: rgb(255, 255, 255);
  align-self: flex-start;
  margin-top: 4px;
  height: 150px;
  padding-bottom: 0;
  transition: all 0.3s ease-out;
  max-height: 24px;
}

.h_atom_dm_title_con {
  display: block;
  padding-bottom: 0px!important;
  align-items: center;
  width: 180px;
  opacity: 1;
}

.h_atom_dm_title {
  padding-bottom: 0px!important;
  margin-bottom: 0px!important;
}

.h_atom_dm_dropdown_item {
  opacity: 0; 
  transition: all 0.3s ease-out;
  line-height: 16px;
  margin: 12px auto 12px auto;
  padding-bottom: 4px;
}

.h_atom_dm_dropdown_con {
  max-height: 0px;
  transition: all 0.3s ease-out;
  background: rgb(255, 255, 255);
  display: flex;
  min-width: 200px;
  flex-direction: column;
  border-radius: 12px;

  box-shadow: 
  4px 4px 6px -1px #cacaca, 
  -4px 4px 8px -1px #cacaca,
  0px -4px 8px -1px #cacaca, 
  0px 8px 17px 4px #cacaca;
}

.h_atom_dm_main_con:hover > .h_atom_dm_dropdown_con {
  transition: all 0.3s ease-in;
  max-height: 400px;
}

.h_atom_dm_main_con:hover > .h_atom_dm_title_con {
  transition: all 0s ease-in;
  opacity: 0;
  display: none;
  height: 0px;
}

.h_atom_dm_main_con:hover .h_atom_dm_dropdown_item {
  opacity: 1;
  transition: all 0.3s ease-in;
}

.h_atom_dm_main_con:hover {
  box-shadow: 
  0px -4px 8px -1px #cacaca, 
  0px 8px 17px 4px #cacaca; 
  max-width: 200px;
  border-radius: 12px 12px 0px 0px;
  max-height: 34px;
  z-index: 3;
  padding-top: 8px;
  transition: height 1s linear;
}


@media screen and (max-width: 930px) {
  .h_atom_dm_title_con {
    width: 180px;
  }

} */